import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/kliniker',
    name: 'Kliniker',
    component: () => import('../views/Kliniker.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/klinik/:id',
    name: 'KlinikDetail',
    component: () => import('../views/Klinik.vue'),
    props: route => ({ id: Number(route.params.id) }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/confirm-email',
    name: 'EmailConfirmation',
    component: () => import('../components/EmailConfirmation.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../components/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../components/ResetPassword.vue'),
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: '/api/UserCustomer/reset-password',
    redirect: to => {
      return { path: '/reset-password', query: { token: to.query.token } }
    }
  },
  {
    path: '/profile-update',
    name: 'ProfileUpdate',
    component: () => import('../components/ProfileUpdate.vue'),
    meta: {
      requiresAuth: true
    },
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BACKEND_BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;