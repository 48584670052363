import store from '../store'; // Ensure the path is correct

async function getJSON(response) {
    if (response.status === 204) return ''; // Handle no content response
    return response.json(); // Parse JSON response
}

function getHeaders() {
    const headers = {
        'Content-Type': 'application/json' // Set content type
    };
    const token = store.getters.getToken; // Retrieve token from Vuex store
    if (token) {
        headers['Authorization'] = `Bearer ${token}`; // Add Authorization header if token exists
    }
    return headers;
}

async function apiService(endpoint, method = 'GET', data) {
    const BASE_URL = process.env.VUE_APP_BACKEND_BASE_URL;
    
    if (!BASE_URL) {
        console.error('Backend base URL is not defined');
        throw new Error('Backend configuration error');
    }

    /* if (process.env.NODE_ENV !== 'production') {
        console.log(`Making ${method} request to ${BASE_URL}${endpoint}`);
    } */

    const config = {
        method,
        headers: getHeaders()
    };

    if (data !== undefined) {
        config.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(`${BASE_URL}${endpoint}`, config);
        if (process.env.NODE_ENV !== 'production') {
            //console.log(`Response status: ${response.status}`);
        }

        if (!response.ok) {
            let errorData;
            try {
                errorData = await response.json();
            } catch {
                errorData = await response.text();
            }
            console.error(`API call error:`, errorData);
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        return await getJSON(response);
    } catch (error) {
        // Enhanced error logging and handling
        console.error('API call error:', error);
        throw error;
    }
}

export { apiService };