<template>
  <v-app>
    <v-app-bar app color="#383e42" dark height="210px" class="mb-10">
      <v-container class="pa-0 fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <h1 id="remiss">Remissbanken</h1>
            <h3 class="subtitle-1">Där patienten alltid hamnar rätt</h3>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="auto" class="text-center">
            <v-btn 
              v-if="showBackButton" 
              @click="goBack" 
              outlined
              class="mr-2"
            >
              Tillbaka
            </v-btn>
            <v-btn
              v-else-if="showProfileButton"
              @click="goToProfile"
              class="profile-btn mr-2"
              color="white"
              outlined
              medium
            >
              <v-icon left>mdi-account</v-icon>
              Profil
            </v-btn>
          </v-col>
          <v-col cols="auto" class="text-center">
            <v-btn @click="handleLogout" outlined>Logga ut</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- Footer content -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: "App",
  computed: {
    ...mapState(['savedCurrentUser', 'isAuthenticated']),
    currentUser() {
      return this.savedCurrentUser;
    },
    showBackButton() {
      return ['ProfileUpdate', 'KlinikDetail'].includes(this.$route.name);
    },
    showProfileButton() {
      return !['ProfileUpdate', 'KlinikDetail', 'Login'].includes(this.$route.name);
    }
  },
  methods: {
    ...mapActions(['logout']),
    async handleLogout() {
      try {
        await this.logout();
        await this.$router.push('/');
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          console.error('Logout failed:', error);
        }
      }
    },
    goToProfile() {
      this.$router.push({ name: 'ProfileUpdate' }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation to profile failed:', err);
        }
      });
    },
    goBack() {
      this.$router.push({ name: 'Kliniker' }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation to Kliniker failed:', err);
        }
      });
    }
  },
  created() {
    this.$store.commit('initializeStore');
  }
};
</script>

<style>
#remiss {
  font-size: 4em;
  font-weight: bold;
}

.profile-btn, .v-btn {
  margin-top: 1em !important;
}

.mr-2 {
  margin-right: 8px !important;
}
</style>