import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { apiService } from '../common/api.service';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['token', 'refreshToken', 'isAuthenticated', 'savedCurrentUser', 'searchParams'],
    storage: window.sessionStorage
  })],
  state: {
    savedCurrentUser: {},
    isAuthenticated: false,
    token: '',
    refreshToken: '',
    kliniker: [],
    searchParams: {
      selectedCity: null,
      selectedSpecialist: null,
      selectedWaitTime: null,
      page: 1,
      itemsPerPage: 5,
    },
    dropdownData: {
      ort: [],
      mottagningKlinik: []
    },
    profile: null,
  },
  mutations: {
    savedCurrentUser(state, data) {
      state.savedCurrentUser = data;
      state.isAuthenticated = true;
    },
    initializeStore(state) {
      if (state.token && state.refreshToken) {
        state.isAuthenticated = true;
      } else {
        state.token = '';
        state.refreshToken = '';
        state.isAuthenticated = false;
      }
    },
    setTokens(state, { token, refreshToken }) {
      state.token = token;
      state.refreshToken = refreshToken;
      state.isAuthenticated = true;
    },
    clearTokens(state) {
      state.token = '';
      state.refreshToken = '';
      state.isAuthenticated = false;
      state.savedCurrentUser = {};
    },
    setKliniker(state, kliniker) {
      state.kliniker = kliniker;
    },
    setSearchParams(state, params) {
      state.searchParams = params;
    },
    updateUserProfile(state, profile) {
      state.savedCurrentUser = { ...state.savedCurrentUser, ...profile };
    },
    setUserProfile(state, profile) {
      state.profile = profile;
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await apiService('/api/UserCustomer/login', 'POST', credentials);
        commit('setTokens', { 
          token: response.data.token, 
          refreshToken: response.data.refreshToken 
        });
        commit('savedCurrentUser', response.data.user);
        return response.data;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        } else {
          console.error('Login error:', error);
          throw new Error('An unexpected error occurred during login.');
        }
      }
    },
    async register(_, userData) {
      try {
        const response = await apiService('/api/UserCustomer/register', 'POST', userData);
        return response.data;
      } catch (error) {
        console.error('Registration error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        }
        throw new Error('An unexpected error occurred during registration.');
      }
    },
    async logout({ commit, state }) {
      try {
        if (state.refreshToken) {
          await apiService('/api/UserCustomer/logout', 'POST', {
            refreshToken: state.refreshToken
          });
        }
      } catch (error) {
        console.error('Logout error:', error.response ? error.response.data : error.message);
      } finally {
        commit('clearTokens');
        if (router.currentRoute.path !== '/') {
          router.push('/').catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              console.error('Navigation error:', err);
            }
          });
        } else {
          window.location.reload();
        }
      }
    },
    async refreshToken({ commit, state }) {
      try {
        const response = await apiService('/api/UserCustomer/refresh-token', 'POST', {
          refreshToken: state.refreshToken
        });
        commit('setTokens', { 
          token: response.data.token, 
          refreshToken: response.data.refreshToken 
        });
        return response.data.token;
      } catch (error) {
        console.error('Token refresh error:', error.response ? error.response.data : error.message);
        commit('clearTokens');
        router.push('/').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error('Navigation error:', err);
          }
        });
        throw error;
      }
    },
    async getClinics({ commit }) {
      try {
        const response = await apiService('/api/KlinkerKlinik/all', 'GET');
        if (response && response.data) {
          commit('setKliniker', response.data);
          return response;
        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching all clinics:', error);
        throw error;
      }
    },
    async getKlinikById(_, id) {
      try {
        const response = await apiService(`/api/KlinkerKlinik/${id}`, 'GET');
        return response.data;
      } catch (error) {
        console.error('Error fetching clinic details:', error);
        throw error;
      }
    },
    async forgotPassword(_, email) {
      try {
        const response = await apiService('/api/UserCustomer/forgot-password', 'POST', { email });
        return response.data;
      } catch (error) {
        console.error('Forgot Password error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        }
        throw new Error('An error occurred while processing your request. Please try again later.');
      }
    },
    async resetPassword(_, payload) {
      try {
        const response = await apiService('/api/UserCustomer/reset-password', 'POST', payload);
        return response.data;
      } catch (error) {
        console.error('Reset password error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        }
        throw new Error('An error occurred while resetting your password. Please try again later.');
      }
    },
    async changePassword(_, payload) {
      try {
        const response = await apiService('/api/UserCustomer/change-password', 'POST', payload);
        return response.data;
      } catch (error) {
        console.error('Change password error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        }
        throw new Error('An error occurred while changing your password. Please try again later.');
      }
    },
    async updateUserProfile({ commit }, profile) {
      try {
        const response = await apiService('/api/UserCustomer/update-profile', 'PUT', profile);
        commit('updateUserProfile', response.data);
        return response.data;
      } catch (error) {
        console.error('Update profile error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        }
        throw new Error('An error occurred while updating your profile. Please try again later.');
      }
    },
    async fetchUserProfile({ commit }) {
      try {
        const response = await apiService('/api/UserCustomer/profile', 'GET');
        if (response && response.data) {
          commit('setUserProfile', response.data);
          return response;
        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Fetch profile error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        }
        throw new Error('An error occurred while fetching your profile. Please try again later.');
      }
    },
    setSearchParams({ commit }, params) {
      commit('setSearchParams', params);
    }
  },
  getters: {
    getToken: state => state.token,
    isAuthenticated: state => state.isAuthenticated,
    currentUser: state => state.savedCurrentUser,
    kliniker: state => state.kliniker,
    searchParams: state => state.searchParams,
  },
  modules: {}
});