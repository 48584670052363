import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Toasted from 'vue-toasted';

// Import Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add the icons to the library
library.add(faUser, faEdit);

// Register the FontAwesomeIcon component globally
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

// Use VueToasted
Vue.use(Toasted, {
  duration: 5000,
  position: 'top-right',
  keepOnHover: true,
  closeOnSwipe: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
